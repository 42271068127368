<template>
	<slot name="outer" :modal="modalRef">
		<template v-if="$slots.default">
			<div class="cropper-target" :class="wrapperClass" v-bind="$attrs" @click="modalRef.show()">
				<slot></slot>
			</div>
		</template>
		<template v-else>
			<div class="cropper-target" :class="wrapperClass" @click="modalRef.show()">
				<template v-if="src">
					<img :key="src+updateKey" :src="src" v-bind="$attrs">
				</template>
				<button v-else :class="$attrs.class ? null : 'btn btn-primary btn-sm'" v-bind="$attrs" >{{$t('Crop')}}</button>
			</div>
		</template>
	</slot>
	<OModal ref="modalRef" @show="onModalShow">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<div class="d-flex" v-if="!disableUserRatios">
						<button class="btn btn-sm btn-secondary me-1" @click="cropperControl.setRatio(1)">1:1</button>
						<button class="btn btn-sm btn-secondary me-1" @click="cropperControl.setRatio(3/4)">3:4</button>
						<button class="btn btn-sm btn-secondary me-1" @click="cropperControl.setRatio(4/3)">4:3</button>
						<button class="btn btn-sm btn-secondary me-1" @click="cropperControl.setRatio(3/2)">3:2</button>
						<button class="btn btn-sm btn-secondary me-1" @click="cropperControl.setRatio(16/9)">16:9</button>
						<div class="d-flex flex-nowrap align-items-center">
							<label class="mx-2">{{$t('Ratio')}}</label>
							<input class="form-control form-control-sm cropper-ratio-input" :title="cropperControl.aspectRatioLocked ? null : $t('Lock the aspect ratio before chaning')"
								type="number" step="0.01" :disabled="!cropperControl.aspectRatioLocked"
								v-model="cropperControl.aspectRatio">
						</div>
						<div v-if="!disableAspectRatioUnlocking" class="form-check ms-2">
							<label class="form-check-label">
							<input class="form-check-input" type="checkbox" v-model="cropperControl.aspectRatioLocked">
								{{$t('Lock aspect ratio')}}
							</label>
						</div>
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>

				<div class="modal-body position-relative" :style="{height: cropperHeight+'px'}">
					<Overlay v-if="cropperControl.loading"/>

					<div v-if="cropperControl.loadFailed" class="position-absolute top-50 start-50 translate-middle" style="top: 50%; left: 50%">{{$t('No image loaded')}}</div>

					<Cropper
						ref="cropperRef"
						class="cropper h-100"
						:src="cropperControl.blobUrl"
						imageRestriction="none"
						:stencil-props="{
							aspectRatio: cropperControl.aspectRatioLocked ? cropperControl.aspectRatio : null
						}"
						@ready="cropperControl.onCropperReady()"
						@error="cropperControl.onCropperError()"
					/>
				</div>

				<div class="modal-footer d-inline">
					<template v-if="cropperControl.progress">
						<div class="alert alert-danger" v-if="cropperControl.progress.error" v-for="[file,value] in cropperControl.progress.files" role="alert">
							<template v-if="value.error">
								<strong class="alert-heading">{{file.name}}</strong>
								<p class="text-truncate mb-0" style="max-length:300px;">{{value.error}}</p>
							</template> 
						</div>
						<div class="progress" v-if="!cropperControl.progress.error">
							<div class="progress-bar" role="progressbar" :style="{width:cropperControl.progress.progress+'%'}" aria-valuenow="cropperControl.progress.progress" aria-valuemin="0" aria-valuemax="100">{{cropperControl.progress.progress}}%</div>
						</div>
						<div class="text-center">
							<small>{{cropperControl.progress.message}} ({{numberUtils.formatFileSize(cropperControl.progress.uploaded)}} {{$t('of')}} {{numberUtils.formatFileSize(cropperControl.progress.total)}})</small>
						</div>
					</template>
					<div class="d-flex justify-content-between">
						<div>
							<button class="btn btn-sm btn-secondary me-2" @click="$refs.fileInput['click']()">
								<input class="d-none" type="file" ref="fileInput" @change="cropperControl.loadImage($event)" :key="cropperControl.blobUrl" accept="image/*">
								{{$t('Browse')}}
							</button>
							<button class="btn btn-sm btn-secondary" disabled :title="$t('Not implemented')">{{$t('Clear')}}</button>
						</div>
						<div>
							<button class="btn btn-sm btn-secondary me-2" data-bs-dismiss="modal">{{$t('Close')}}</button>
							<button class="btn btn-sm btn-primary" @click="cropperControl.saveImage()">{{$t('Save')}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</OModal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { Overlay } from 'o365-ui-components';
import { Cropper } from 'cropper'
import { numberUtils } from 'o365-utils';
import CropperControl from 'o365.libraries.controls.Cropper.ts';

interface Props {
	dataObject: Object
	fieldName?: string;
	aspectRatio?: number;//
	src?: string;
	wrapperClass?: any;
	/** Disable unlocking of the aspect ratio */
	disableAspectRatioUnlocking?: boolean
	/** Disable chaning ratios by user */
	disableUserRatios?: boolean
};

const props = withDefaults(defineProps<Props>(), {
	aspectRatio: 3/4,
	disableUserRatios: false,
	disableAspectRatioUnlocking: false,
});

const cropperRef = ref(null);
const modalRef = ref(null);
const cropperHeight = ref(null);
const imgLoading = ref(true);
const updateKey = ref(true);

const cropperControl = ref(new CropperControl({
	dataObject: props.dataObject,
	fieldName: props.fieldName,
	aspectRatio: props.aspectRatio,
	cropperFunctions: {
		openModal: () => modalRef.value.show(),
		closeModal: () => { modalRef.value.hide(); window.setTimeout(() => { updateKey.value != updateKey.value; }, 500)},
		getResult: () => cropperRef.value?.getResult()
	},
}));

function onModalShow() {
	cropperHeight.value = Math.floor((window.innerHeight * 0.9) -200);
	cropperControl.value.reset(props);
}

watch(() => cropperControl.value.blobUrl, () => {
	cropperControl.value.loading = true;
	cropperControl.value.loadFailed = false;
});

defineExpose({cropper: cropperRef, cropperControl});

</script>

<script lang="ts">
import { loadCdnStyle } from 'o365.modules.helpers.js';
loadCdnStyle('cropper/style.css');
loadCdnStyle('cropper/theme.compact.css');
</script>

<style scoped>
.cropper-ratio-input {
	width: 70px;
}

.cropper-target {
	cursor: pointer;
}
</style>